import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { isWebpSupported } from "react-image-webp/dist/utils";
// import LazyLoad from 'react-lazyload';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//mport HomeBannerImage from '../../Assets/Images/basement_final.jpg';

//import HomeBannerImageWebP from '../../Assets/Images/basement_final.webp';
//import Banner1 from '../../Assets/Images/basement_final.jpg';

import HomeBannerImage from '../../Assets/Images/AUA_6102_w.jpg';
// import HomeBannerImageWebP from '../../Assets/Images/Basement035.jpg';
import Banner1 from '../../Assets/Images/Basement035.jpg';

import promo2 from '../../Assets/Images/basementsspecoffer.gif';

// import Banner1 from "../../Assets/Images/mobile-home-banner/1.jpg";
// import Banner2 from "../../Assets/Images/mobile-home-banner/2.jpg";
// import Banner3 from "../../Assets/Images/mobile-home-banner/3.jpg";
// import Banner4 from "../../Assets/Images/mobile-home-banner/4.jpg";
// import Banner5 from "../../Assets/Images/mobile-home-banner/5.jpg";
// import Banner6 from "../../Assets/Images/mobile-home-banner/6.jpg";
// import Banner7 from "../../Assets/Images/mobile-home-banner/7.jpg";
// import Banner8 from "../../Assets/Images/mobile-home-banner/8.jpg";
// import Banner9 from "../../Assets/Images/mobile-home-banner/9.jpg";

/* eslint-disable */
class MobileHomeHeader extends Component {
  render() {
    const settings = {
      autoplay: true,
      arrows: false,
      slidesToShow: 1,
      centerPadding: 0,
      speed: 1000
    };
    const HomePageBanner = [
      { src: Banner1 },
      // { src: Banner2 },
      // { src: Banner3 },
      // { src: Banner4 },
      // { src: Banner5 },
      // { src: Banner6 },
      // { src: Banner7 },
      // { src: Banner8 },
      // { src: Banner9 },
    ];
    return (
      <>
      <section id="mobileBanner"
        style={{
          backgroundImage: `url(${ 'isWebpSupported()' ? HomeBannerImage : HomeBannerImage })`
        }}
      >     
         <Link to="/estimate">
          <img className="promo2" style={{ width: '110px', margin: '10px', filter: 'drop-shadow(0 2px 4px rgba(0,0,0,.4))' }} src={promo2}/>   
         </Link>


        <div className="footerInfoBar">
              <div className="itemInfo">
                <b className="border">Ivan A.</b>
                <span className="border">CEO AGM Renovations</span>
              </div>
              <div className="itemInfo">
                <b>Mike Holmes</b>
                <span>TV Star & Contractor</span>
              </div>
          </div>
      </section>
      <section id="mobile-home-banner">

        {/* <LazyLoad once> */}
        {/* <Suspense fallback={<div>Loading...</div>}> */}
        {/*<Slider className={'mobile-slider'} {...settings}>
          {HomePageBanner.map((banner, i) => (
            <div key={"home-page-banner" + i}>
              <img src={banner.src} alt={"Home page banner"} />
            </div>
          ))}
        </Slider>*/}
        {/* </Suspense> */}
        {/* </LazyLoad> */}
        
        <div style={{ width: '100%', paddingTop: '100px', position: 'absolute' }} 
            className="home-banner-button-mobile mt-0 mt-md-0">
          <Link to="/estimate" className="btn blue3d-btn">
            Get a Free Quote
          </Link>
        </div>
        {/* <div className="banner-content d-flex align-items-center"> */}
        <div className="mobile-banner-content" style={{marginTop: '-9px'}}>
          <div className="left">
            <h1>
              Basement Renovations <br/> <span style={{color: '#05addd', fontSize: '20px'}}>Done Right!</span>
            </h1>
            {/* <span className="sub-head">
                Serving Ontario for over 20 years
            </span> */}
          </div>
        </div>
        {/* </div> */}


      </section>
      </>
    );
  }
}

export default MobileHomeHeader;
