import React, { Component, createRef } from 'react';
import Slider from 'react-slick';
// import UserInfo from './UserInfo';
import s from './styles.module.scss';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// import UserPhotoTwo from './../../Assets/Images/gallery/24Klein.jpg';
// import UserPhotoThirteen from './../../Assets/Images/gallery/196BrownleyLanein.jpg';
// import UserPhotoTen from './../../Assets/Images/gallery/1888CheesewrightCourt.jpg';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MPTest from './../../Assets/Images/gallery/24Test.jpg';



export const sliderSettings = {
    dots: true,
    // infinite: true,
    // speed: 500,
    // autoplay: true,
    // autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
        {
            breakpoint: 769,
            settings: {
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const AGMTestimonials = [
  {
    title: '24 Klein Mills Rd',
    subtitle: 'See more of this Project',
    virtualTourUrl: "https://my.matterport.com/show/?m=8S53JhBSUKy&brand=0",
    previrewImage: MPTest,
  }
];
// const AGMTestimonials = [
//   {
//     title: '24 Klein Mills Rd',
//     subtitle: 'See more of this Project',
//     virtualTourUrl: "https://my.matterport.com/show/?m=qKisYdUcit3",
//     previrewImage: UserPhotoTwo,
//   },
//   {
//     title: '45 Buggey Ln',
//     subtitle: 'See more of this Project',
//     virtualTourUrl: "https://my.matterport.com/show/?m=BJ1L99mTS4c",
//     previrewImage: UserPhotoThirteen,
//   },
//   {
//     title: '1888 Cheesewright Court',
//     subtitle: 'See more of this Project',
//     virtualTourUrl: "https://my.matterport.com/show/?m=5JrD7rKQD5c",
//     previrewImage: UserPhotoTen,
//   }
// ];

const playSvg = (<svg width="45" height="53" viewBox="0 0 45 53" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M41.4062 22.2461L7.03125 1.93359C4.19922 0.273438 0 1.93359 0 5.9375V46.5625C0 50.2734 3.90625 52.5195 7.03125 50.6641L41.4062 30.3516C44.4336 28.4961 44.4336 24.1016 41.4062 22.2461ZM39.8438 27.6172L5.46875 47.9297C4.39453 48.5156 3.125 47.832 3.125 46.5625V5.9375C3.125 4.375 4.6875 4.17969 5.46875 4.66797L39.8438 24.9805C40.8203 25.5664 40.8203 27.0312 39.8438 27.6172Z" fill="white"></path></svg>);

export class GallerySlider extends Component {


    static defaultProps = {
        reviews: AGMTestimonials,
        className: '',
    };

    constructor(props) {
      super(props);
      this.state = {
          isOpenedDialog: false,
          url: '',
        }

        this.slider = createRef(null);
    }

    handleClose = () => {
        this.setState({ isOpenedDialog: false });
    }

    componentDidMount() {
      this.slider.current.slickGoTo(1);
    }

    componentDidUpdate() {
      this.slider.current.slickGoTo(1);
    }

    render() {
        const { reviews, className } = this.props;
//  className={s.Slider_review} style={{background: review.previrewImage}} 
        return (
          <>

            <Slider ref={this.slider} className={`${s.Slider} ${className}`} {...sliderSettings}>
                {reviews.map((review, index) => (
                    <div className={s.Slider_item_gallery}  key={index}>
                    <div className={s.Slider_item_img} style={{ backgroundImage: `url(${review.previrewImage})` }}></div>

                      { review.virtualTourUrl && 
                      <div className={s.playVideo} onClick={()=>{ this.setState({ isOpenedDialog: true, url: review.virtualTourUrl }); }}>
                        <div className={s.playButton}>
                            {playSvg}
                        </div>  
                      </div> }   

                      <p style={{
                        position: 'absolute',
                        top: '217px',
                        color: '#333',
                        left: 0,
                        margin: '0 auto',
                        width: '100%',
                        textAlign: 'center',
                      }}>
                        {review.title}
                      </p>
                      <p style={{
                        position: 'absolute',
                        top: '250px',
                        fontSize: '14px',
                        color: '#333',
                        left: 0,
                        margin: '0 auto',
                        width: '100%',
                        textAlign: 'center',
                      }}>
                        {review.virtualTourUrl && review.subtitle}
                      </p>
                    </div>
                ))}
            </Slider>

            <Dialog
              open={this.state.isOpenedDialog}
              onClose={this.handleClose}
              fullWidth={true}
              maxWidth={'lg'}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >

              <DialogContent  style={{height: '680px', overflow: 'hidden',  padding: '0'}}>
                <iframe src={this.state.url} style={{  width: '100%', height: '100%'}} className="modal3dWindow" title="video"></iframe>
              </DialogContent>

              <HighlightOffIcon style={{
                position: 'absolute',
                background: '#fff',
                borderRadius: '50%',
                right: '6px',
                top: '6px',
                cursor: 'pointer',
                transform: 'scale(1.5)'
              }} onClick={this.handleClose}/>

            </Dialog>

          </>
        );
    }
}

export default GallerySlider;
